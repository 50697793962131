<template>
  <!-- <img alt="Vue logo" src="./assets/home_bg.jpg" width="100%" height="300px"> -->
  <view style="flex: 1; display: flex;flex-direction: row;background-color: #000;">
    <img src="./assets/gl.png" />
    <view style="flex: 10;display: flex;flex-direction: column; align-items: flex-start;justify-content: center;color: #aaa;">
      <text style="font-size: 18px;text-shadow: 3px 3px 3px #aaa">多云彗</text>
      <text style="font-size: 15px;text-shadow: 3px 3px 3px #aaa">duoyunhui</text>
    </view>
    <view style="flex: 3; display: flex;justify-content: flex-end;">
      <button style="color: white;font-size: 12px;background-color: rgba(0,0,0,0);border: 0;margin-right: 20px">HOME</button>
      <!-- <button style="width: 40%;color: white;font-size: 12px; background-color: rgba(0,0,0,0);border: 0;">PRODUCT</button> -->
      <!-- <button style="width: 30%;color: white;font-size: 12px; background-color: rgba(0,0,0,0);border: 0;">ABOUT</button> -->
    </view>
  </view>
  <img src="./assets/digit2.jpg" width="100%" height="auto" />
  <p style="font-size: 30px;font-weight: 700;">数字化</p>
  <p style="font-size: 20px;font-weight: 700;">数字化赋能传统业务转型升级</p>
  <img src="./assets/digit1.png" width="50%" height="auto" />

  <p style="font-size: 30px;font-weight: 700;">云计算</p>
  <p style="font-size: 20px;font-weight: 700;">强大的算力支撑业务的高效率</p>
  <img src="./assets/cloud1.png" width="50%" height="auto" />

  <p style="font-size: 30px;font-weight: 700;">多平台</p>
  <p style="font-size: 20px;font-weight: 700;">多端支持的协作方式</p>
  <img src="./assets/platform.png" width="50%" height="auto" />

  <p style="font-size: 30px;font-weight: 700;">一站式</p>
  <p style="font-size: 20px;font-weight: 700;">端到端的一站式解决方案</p>
  <img src="./assets/solution.png" width="50%" height="auto" />

  <!-- <p style="font-size: 30px;font-weight: 700;">公司产品</p>
  <p style="font-size: 20px;font-weight: 700;">翘铺</p>
  <img src="./assets/qiaopuminiprogram.jpg" width="50%" height="auto" /> -->
  <li style="margin-top: 100px;margin-bottom: 50px;"><a href="https://beian.miit.gov.cn/#/query/webSearch" target="_blank" rel="noopener">蜀ICP备2023013584号</a></li>

  <!-- <HelloWorld msg="Welcome to Duoyunhui" /> -->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
